.ContactManagementModal {
    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        width: 6px;
        height: 6px;
        margin: 5px;
        border-radius: 6px;
        background: #FFF;
    }

    .fieldRight {
        margin-left: auto;
    }

    .searchselect-light__input {
        color: #212529;
        background: #F4F7FA;
        font-size: 16px;
    }

    .selectFilled {
        color: #212529;
        background: #F4F7FA;
    }

    .form-group {
        margin-bottom: 0.25rem;
    }

    .companySelect {
        width: 80%;
        border-right: 1px solid #DEE2E6;
    }

    .companyRow {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        background: #F4F7FA;
        margin-bottom: 1rem;

        & > p {
            margin: 0;
            text-align: center;
            flex: 1;
            color: #4A525C !important;
        }
    }

    .csvUpload {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 43px;

        & > input {
            height: 100%;
        }

        & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            pointer-events: none;
            cursor: pointer;
            border: 1px solid #5e72e4;
            text-align: center;
            background: #FFF;
            line-height: 40px;
            border-radius: 10px;
        }
    }
}
