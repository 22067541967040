.sidenav.navbar-vertical {
    background-color: #F4F7FA !important;
    overflow: hidden;
}

.navbar-inner .nav-link.active {
    background: #F0F3F5;
    border: 1px solid #E9ECEF;
}

.navbar-inner .menuitem {
    margin: 0 0 0 -0.625rem;
    text-align: center;
    transition: margin 200ms ease-in-out;
}

.g-sidenav-show .navbar-inner .menuitem {
    margin: 0 0.5rem 0 0.125rem;
}

.navbar-inner .nav-link {
    line-height: 1;
}

.navbar-inner .nav-link.active svg > * {
    fill: #5E72E4;
}

.navbar-inner hr {
    border-top: 1px solid #E9ECEF;
    margin: 6px 24px;
}

.sidenav .navbar-inner .nav-item {
    margin-top: 6px !important;
    margin-bottom: 6px !important;
}

.sidenav .navbar-inner .navbar-collapse:before {
    display: none;
}

.g-sidenav-hidden .sidenav .navbar-inner .nav-link {
    padding: 0.4375rem 0.75rem !important;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
    transition: all 400ms ease-in-out;
}

.g-sidenav-show .sidenav .navbar-inner .nav-link {
    padding: 0.4375rem 0.75rem !important;
    margin-right: 1rem !important;
    margin-left: 1rem !important;
    transition: all 400ms ease-in-out;
}

.sidenav .navbar-inner .menuitem {
    line-height: 20px;
}

.sidenav .navbar-inner .nav-link-text {
    font-size: 16px;
    line-height: 20px;
}

.SideNav-brand {
    padding: 1.5rem 1rem 1.125rem !important;
}

.SideNav-brand > img {
    max-height: 3.375rem !important;
}

.g-sidenav-hidden .SideNav-build {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

.g-sidenav-show .SideNav-build {
    opacity: 1;
    transition: opacity 400ms ease-in-out;
}

.sidenav .navbar-inner .nav-link > i {
    transition: all 400ms ease-in-out;
}

.g-sidenav-hidden .sidenav .navbar-inner .nav-link > i {
    min-width: 2.5rem !important;
    transition: all 400ms ease-in-out;
}

.sidenav .sidenav-toggler-inner {
    font-size: 2rem;
}

.sidenav .sidenav-toggler-inner > svg {
    transition: transform 400ms ease-in-out;
}

.sidenav .sidenav-toggler-inner.active > svg {
    transform: rotateZ(180deg);
}
