@import url("https://fonts.googleapis.com/css?family=Oxygen:400,700|Poppins&display=swap");

:root {
  --box-shadow: 6px 4px 3px 2px rgba(68, 68, 68, 0.5);
  --primary-color: whitesmoke;
  --secondary-color: #444444;
  --contrast-color: #07973c;
  --background-color: #2dce89;
  --font: "Oxygen", sans-serif;
}

.top-header {
  // background: linear-gradient(0.25turn, #2dce89, 60%, #4fa06d);
  background: #4cc3fa;
  width: 100%;
  height: 1rem;
}

a {
  text-decoration: none;
}

.logo {
  background-color: transparent;
  padding: 10px;
  margin-top: 10px;
  width: 30%;
  align-self: baseline;
}
#labels {
  font-family: "Oxygen", sans-serif;
  color: var(--secondary-color);
  font-weight: bold;
  font-family: var(--font);
  line-height: 1;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f7faf6dd;
}

h1 {
  line-height: 1;
}

// #logContainer{
//     border-radius: 10px;
//     margin-top: 10%;
//     width: 50vw;
//     padding: 4%;
//     background-color:rgb(246, 248, 249);
//     box-shadow: 2px 3px 2px #7d7c7c;
// }

#buttons {
  background-color: #07973c;
  border-style: none;
  /* font-family: "Oxygen", sans-serif; */
  font-weight: bold;
  margin-bottom: 0.25rem;
}

#security-btn {
  background-color: #07973c;
  border-style: none;
  /* font-family: "Oxygen", sans-serif; */
  font-weight: bold;
}

.iconTxtCancel {
  font-weight: bold;
  color: red;
  font-size: 40px;
  text-align: center;
  margin-bottom: 13px;
}

.incomingCall {
  position: absolute;
  display: flex;
  align-self: center;
  justify-self: center;
}

.delModal {
  position: fixed;
  background: lightgrey;
  width: 80%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 99999;
}

.delModalOverlay {
  position: fixed;
  padding: 20vh 7vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999998;
  display: flex;
  flex-direction: column;
}

.endCallBtn {
  padding-top: 7px;
  margin-right: 10px;
  height: 85px;
  width: 85px;
  background-color: var(--primary-color);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
}

.endCallBox {
  margin-right: 10px;
  height: 85px;
  width: 85px;
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  box-shadow: var(--box-shadow);
  margin-right: 30px;
}

.endCallContainer {
  display: flex;
  justify-content: center;
  margin-top: 50%;
}

.entriesTxt {
  text-align: center;
  font-family: "Oxigen", sans-serif;
  font-size: 30px;
}

#buttons {
  background-color: var(--contrast-color);
  border-style: none;
  font-weight: bold;
}

#btn-table {
  background-color: var(--contrast-color);
  font-weight: bold;
}

table {
  font-family: var(--font);
  background-color: var(--primary-color);
  padding: 5px;
  overflow: auto;
}

td,
th {
  color: var(--secondary-color);
  padding: 0.05rem;
  margin-left: 0;
  text-align: center;
}

.start {
  font-size: 2em;
  color: black;
  text-align: center;
  font-weight: bold;
  margin-top: 30px;
}
.loginTxt {
  font-size: 2em;
  color: var(--secondary-color);
  text-align: center;
  font-weight: bold;
  margin-top: 80px;
}
.chatContainer {
  background: url(../assets/greenbg.png);
  background-size: cover;
}

.incomingCallText {
  margin-top: 14px;
  font-weight: bold;
  color: white;
  font-size: 24px;
  font-family: "Oxygen", sans-serif;
}

.incomingCallContainer {
  display: inline-flex;
  margin-top: 20px;
  margin-left: 700px;
}

.incomingCallBoxGreen {
  height: 150px;
  width: 300px;
  border: 2px solid #4caf50;
  background-color: whitesmoke;
  border-radius: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.incomingCallBoxRed {
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  background: red;
  padding: 5px;
  width: 60px;
  height: 60px;
  width: 60px;
  border: 2px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.userImages {
  width: 400px;
  margin-bottom: 20px;
}

/* Calls animation */
.ringing-phone {
  line-height: 50px;
  font-size: 2.5em;
  animation: shake 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.ringing-phone-accept {
  background-color: green;
  line-height: 50px;
  font-size: 2.5em;
  animation: shake 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.ringing-phone-decline {
  background-color: red;
  line-height: 50px;
  font-size: 2.5em;
  animation: shake 2s infinite cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.ringing_phone:after,
.ringing_phone:before {
  position: absolute;
  content: "";
  opacity: 0;
  border-right: 2px solid #ffffff;
  width: 15px;
  height: 15px;
  left: 40%;
  top: 28%;
  border-radius: 50%;
  transform: rotate(-40deg);
  animation: fadeInOne 2s infinite both;
}

.ringing_phone:before {
  width: 20px;
  height: 20px;
  left: 40%;
  top: 20%;
  animation: fadeInTwo 2s infinite both;
}
@keyframes shake {
  5%,
  45% {
    transform: rotate3d(0, 0, 1, -7deg);
  }
  10%,
  40% {
    transform: rotate3d(0, 0, 1, 7deg);
  }
  15%,
  25%,
  35% {
    transform: rotate3d(0, 0, 1, -7deg);
  }
  20%,
  30% {
    transform: rotate3d(0, 0, 1, 7deg);
  }
  51% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes fadeInOne {
  45% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInTwo {
  55% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    height: auto;
  }
  #logContainer {
    margin-top: 300px;
    width: 80vw;
  }
  .start {
    margin-top: 40px;
  }

  td {
    text-align: center;
    padding: 2px;
  }
  .incomingCallContainer {
    margin-top: 22px;
    margin-left: 63px;
  }
}

.table thead th {
  text-align: center;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

.logout-btn {
  background-color: white;
  border-radius: 0.25rem;
  height: 2rem;
  margin-right: 1rem;
}

.add-visitor-btn {
  background-color: white;
  border-radius: 0.25rem;
  height: 2rem;
}

.pagination-nav {
  display: flex;
  margin: 12px 8px;
}

.create-security-btn {
  background-color: white;
  border-radius: 0.25rem;
  height: 2rem;
  margin-left: 1rem;
}

.search-input {
  width: 20rem !important;
}
