
.chart-card {
    margin-bottom: 5%;
}

.incomingCall {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.incomingCallText{
    margin-top: 14px;
    font-weight: bold;
    color:white;
    font-size: 24px;
    font-family: 'Oxygen', sans-serif ;
}

.incomingCallBox {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: green;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    transform: scaleX(-1);
}
.incomingCallBox2 {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.visitor-call-card {
    margin-top: 5rem;
    height: 20rem;
}

.latest-entry-card {
    margin-top: 5rem;
}

.phone {
    font-size: 1.5rem;
}

.userImages {
    width: 400px;
    margin-bottom: 20px;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Calls animation */
.ringing-phone{
    line-height: 50px;
    font-size: 1.5em;
    animation: shake 2s infinite cubic-bezier(.36, .07, .19, .97) both;

}
.ringing_phone:after,
.ringing_phone:before {
    position: absolute;
    content: '';
    opacity: 0;
    border-right: 2px solid #ffffff;
    width: 15px;
    height: 15px;
    left: 40%;
    top: 28%;
    border-radius: 50%;
    transform: rotate(-40deg);
    animation: fadeInOne 2s infinite both;
}

.ringing_phone:before {
    width: 20px;
    height: 20px;
    left: 40%;
    top: 20%;
    animation: fadeInTwo 2s infinite both;
}
@keyframes shake {
    5%,
    45% {
        transform: rotate3d(0, 0, 1, -7deg);
    }
    10%,
    40% {
        transform: rotate3d(0, 0, 1, 7deg);
    }
    15%,
    25%,
    35% {
        transform: rotate3d(0, 0, 1, -7deg);
    }
    20%,
    30% {
        transform: rotate3d(0, 0, 1, 7deg);
    }
    51% {
        transform: rotate3d(0, 0, 0, 0deg);
    }
    100% {
        transform: rotate3d(0, 0, 0, 0deg);
    }
}

@keyframes fadeInOne {
    45% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

@keyframes fadeInTwo {
    55% {
        opacity: 0
    }
    100% {
        opacity: 1
    }
}

.active {
border-color: orange;

}

.wrapperTitle {
    font-size: 24px !important;
    color: #67707A !important;
    font-weight: 400 !important;
}

.mainWrapper {
    padding: 0 !important;
    max-width: unset !important;

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    .btn.btn-primary, .btn.btn-secondary {
        font-size: 16px;
        font-weight: 400;
        box-shadow: none;
    }

    .btn.btn-secondary {
        background-color: #F0F3F5 !important;
    }
}

.noPadding {
    padding: 0 !important;
}

.latest-entry-card {
    padding: 0 94px;
    border-radius: 0 !important;
    margin: 0 !important;
}

.contentContainer {
    border: 1px solid #DEE2E6;
    border-radius: 4px;
    margin-bottom: 90px;
}

.align-items-center.table-flush.table {
    border: none;
}

.card .table th {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 16px;
    color: #666666;
    text-align: left;
    border-bottom: 1px solid #E9ECEF;
}

.card .table td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    text-align: left;
    border-bottom: 1px solid #E9ECEF;

    &.small {
        font-size: 12px;
        line-height: 16px;
    }

    &.center {
        text-align: center;
    }

    &.flagger {
        padding: 0 1.625rem !important;
    }
}
