body > .ConfigItem {
  border: 1px solid #E9ECEF;
  z-index: 100;
}

.ConfigureSteps {
  display: flex;
  flex-direction: column;

  &-content {
    padding: 7rem 5.875rem 1rem;
  }

  &-topControls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
  }

  &-error {
    color: red;
    text-align: center;
    font-weight: bold;
  }

  &-toast {
    width: 20rem;
    background-color: #333;
    border-radius: 0.375rem;
    padding: 0.625rem 0.75rem;

    & > button {
      margin: auto 0;
      padding-bottom: 0.125rem;

      & > svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.ConfigList {
  > ul {
    padding: 0;
    border: 1px solid #E9ECEF;
    border-radius: 0.75rem;
    list-style-type: none;
    overflow: hidden;

    & li:last-child {
      border-bottom: none;
    }
  }

  &.dragging * {
    pointer-events: none;
  }
}

.ConfigItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #E9ECEF;
  background-color: #fff;

  &.draggable {
    cursor: grab;

    & > i {
      visibility: visible;
    }
  }

  & > i {
    margin: 0 2rem 0 1rem;
    visibility: hidden;
  }

  &-label {
    font-weight: bold;
    flex: 0.175;
    margin: 0;
    padding: 0 1rem;
    flex-shrink: 0;
    color: #333;
  }

  &-description {
    flex: 0.6;
    margin: 0;
    padding: 0 1rem;
    flex-shrink: 0;
    color: #666;
  }

  &-variants {
    display: flex;
    flex-wrap: wrap;
    flex: 0.2;
    flex-shrink: 0;
    justify-content: flex-end;
    padding-left: 1rem;
    overflow: hidden;

    &-item {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
      padding: 0.1rem 0.4rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      background: #E9ECEF;
      color: #333;
      word-break: break-word;
      overflow: hidden;
    }
  }

  &-controls {
    position: absolute;
    right: 3.75rem;
    opacity: 0;
    transition: opacity 150ms ease-out;

    &.visible {
      opacity: 1;
    }
  }

  &-controlIcon {
    padding-bottom: 0.1rem;
    margin-right: 0.375rem;
    width: 1.25rem;
    height: 1.25rem;
    pointer-events: none;
  }
}

.AddStepModal {
  &-content {
    display: flex;
    flex-wrap: wrap;
  }
  &-item {
    text-align: center;
    cursor: pointer;
    width: 33%;

    &.hidden {
      color: #B2B2B2;
      cursor: not-allowed;
      pointer-events: none;

      .AddStepModal-item-icon {
        svg path {
          fill: #B2B2B2;
        }
      }
    }

    &-icon {
      width: 7rem;
      height: 7rem;
      border: 1px solid #B2B2B2;
      border-radius: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem auto;

      svg path {
        fill: #605FF3;
      }
    }
  }
}

.MessageModal {
  &-messageInput.form-control {
    height: 15rem;
  }

  &-editor {
    &-image {
      display: block;
      max-width: 100%;
      max-height: 20em;
      margin: 1rem 0;

      &.active {
        box-shadow: 0 0 0 3px #B4D5FF;
      }
    }
  }

  &-toolbar {
    display: flex;
    flex-wrap: wrap;

    &-divider {
      background-color: #E9ECEF;
      width: 1px;
      height: 2rem;
      margin: 0.5rem 0.25rem;
    }

    &-item {
      width: 2.5rem;
      height: 2.5rem;
      border: 1px solid #E9ECEF;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.06);
      margin: 0.25rem;
      padding: 0;
      background-color: #F0F3F5;

      &.active {
        border: 1px solid #605FF3;
      }

      &-fileInput {
        position: relative;
        cursor: pointer;

        > input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;

          &[type=file], &[type=file]::-webkit-file-upload-button {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-translationBlock {
    border-top: 1px solid #E9ECEF;

    & > h2 {
      margin-top: 1rem;
      font-weight: bold;
    }
  }
}

.QuestionModal {
  &-answers {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;

    &-item {
      border-radius: 0.25rem;
      margin-right: 0.5rem;
      padding: 0.25rem 0.5rem;
      background: #E9ECEF;
      color: #333;

      > i {
        cursor: pointer;
      }
    }
  }

  &-info {
    color: #666666;
  }

  &-questionInput.form-control {
    height: 6rem;
  }
}

.LanguagesList {
  padding: 20px;
  background: #FFF;
  border: 1px solid #E9ECEF;
  border-radius: 0.75rem;
  list-style-type: none;
  overflow: hidden;

  > h1 {
    font-size: 24px;
    font-weight: bold;
  }

  &-wrapper {
    padding: 10px;
    margin-bottom: 20px;
  }
}
