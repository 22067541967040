.error {
	color: red;
	font-size: 12.5px;
}

button {
	font-size: 0.9rem;
	font-weight: 700;
	border: none;
	border-radius: 3px;
	padding: 0.3rem 1rem;
	margin-left: 0.5rem;
}

.button-default {
	background: #247ba0;
	color: #fff;
}

.mymodal {
	position: fixed;
	// padding-top: 30%;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);

	border: 1px solid #ccc;
	background: #fff;
	// background: green;
	overflow: auto;
	border-radius: 4px;
	outline: none;
	padding: 20px;
}

.myoverlay {
	position: fixed;
	padding: 20vh 7vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 999;
}

.saveButton {
	background-color: green;
}
.deleteButton {
	background-color: red;
}

// .ReactModal__Overlay {
// 	opacity: 0;
// 	transition: opacity 500ms ease-in-out;
// }

// .ReactModal__Overlay--after-open {
// 	opacity: 1;
// }

// .ReactModal__Overlay--before-close {
// 	opacity: 0;
// }

.delModal {
	position: fixed;
	background: lightgrey;
	width: 80%;
	height: 40vh;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	// z-index: 99999;
}

.delModalOverlay {
	position: fixed;
	padding: 20vh 7vw;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	// z-index: 999998;
	display: flex;
	flex-direction: column;
}

.delModalButtons {
}

.email{
    background-color: white;
}

.sms{
    background-color: white;
}

.btn-group {
    background-color:rgba(240,243,245,1);
    border-radius: 12px;
    padding: 6px;
	color: #fff;
}

.btn-email{
    background-color: rgba(240,243,245,1);
    border-radius: 12px;
}

.btn-sms{
background-color: rgba(240,243,245,1);
border-radius: 12px;

}

.btn-active{
    background-color: white;
    border-radius: 12px;

}
