body{
    background-color: #2dce89;   
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}


.endCallContainer {
    display: flex;
    justify-content: center;
    margin-top: 20%;
}

.iconTxtCancel{
    font-weight: bold;
    color: red;
    font-size: 40px;
    text-align: center;
    margin-bottom: 13px;
}

.endCallBtn{
    padding-top: 7px;
    margin-right: 10px;
    height: 85px;
    width: 85px;
    background-color: var(--primary-color);
    border-radius: 50%;
    box-shadow: var(--box-shadow);
}

.remoteMediaContainer {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.localMediaContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ghostDiv {
    width: 100px;
}

.loading {
    text-align: center;
}

.loadingText {
    font-size: 24px;
}

.localMedia video {
    width: 200px;
    border-radius: 10px;
}

.remoteMedia {
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
}

.remoteMedia video {
    height: 100%;
    border-radius: 10px;
}

.endCallBox {
    height: 151px;
    width: 200px;
    border: 2px solid rgb(79, 79, 211);
    background-color: rgb(240, 240, 240);
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    /* border: solid black; */
}

.endCallText {
    font-size: 1.5em;
    color: black;
    text-align: center;
}

.delModalOverlay {
    position: fixed;
    padding: 5vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    // z-index: 999998;
    display: flex;
    flex-direction: column;
  }