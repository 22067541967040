.error {
  color: red;
  font-size: 12.5px;
}

.delModal {
  position: fixed;
  background: lightgrey;
  //   width: auto;
  //   height: auto;
  //   top: 40%;
  //   left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99996;
}

.delModalOverlay {
  position: fixed;
  padding: 20vh 7vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99995;
  display: flex;
  flex-direction: column;
}

.delModalAdmin {
  position: fixed;
  background: lightgrey;
    width: 60vw;
    height: 28vh;
    top: 40%;
    left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99996;
}

.delModalZone {
  position: fixed;
  background: lightgrey;
    width: 60vw;
    height: auto;
    top: 40%;
    left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.delModalOverlayZone {
  position: fixed;
  padding: 20vh 7vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99998;
  display: flex;
  flex-direction: column;
}
