.incomingCallContainer {
    display: flex;
    justify-content: center;
}

.incomingCallBoxGreen {
    height: 150px;
    width: 300px;
    border: 2px solid rgb(9, 255, 0);
    background-color: rgb(240, 240, 240);
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    /* border: solid black; */
}

.incomingCallBoxRed {
    height: 150px;
    width: 300px;
    border: 2px solid rgb(255, 0, 0);
    background-color: rgb(240, 240, 240);
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    /* border: solid black; */
}

.incomingCallText {
    font-size: 1.5em;
    color: black;
    text-align: center;
}

table, th, td {
    border: 1px solid rgb(177, 177, 177);
}


.userImages {
    width: 400px;
    margin-bottom: 20px;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}