.dropdown-menu {
  max-height: 220px;
  overflow-y: scroll;
}

::-webkit-scrollbar { 
  background: rgba(0, 0, 0, 0);
  width: 6px;
}
::-webkit-scrollbar-thumb { 
  background: #C6C6C6;
}