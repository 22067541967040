html, body {
    height: 280px;
}

.cardContainer p {
    margin: 0 !important;
    font-weight: 500 !important;
    line-height: 1.5 !important;
}

.cardContainer {
    display: flex;
    flex-direction: column;
    border: 2px solid #000;
    border-radius: 10px;
    width: 400px;
    height: 310px;

    @media print {
        transform: rotateZ(90deg) scale(3.35);
        margin-top: 600px;
        margin-left: 300px;
    }
}

.pictureAndLogoContainer {
    display: flex;
    justify-content: space-between;
}

.userPictureImage {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-color: #EEE;
}

.logoImage {
    width: 200px;
    object-fit: cover;
    align-self: flex-start;
    margin-right: 20px;
    margin-top: 20px;
}

.nameAndDateContainer {
    margin-top: -20px;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
}

.nameText {
    color: #000;
    font-size: 30px;
    flex: 2;
    align-self: flex-end;
}

.dateContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
}

.dateText {
    color: #000;
    font-size: 21px;
}

.visitingName {
    color: #000;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

.bottomCardContainer {
    display: flex;
}

.visitorBadgeContainer {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-top: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(16, 192, 219, 0.25);
    border-radius: 10px;
}

.visitorText {
    color: #000;
    font-size: 20px;
    font-weight: bold;
    text-transform: capitalize;
}
