.Table {
    &-wrapper {
        border: 1px solid #DEE2E6;
        border-radius: 4px;
    }

    &-header {
        display: flex;
        align-items: center;
        padding: 16px;
    }

    &-options {
        flex-shrink: 0;
        margin-left: auto;
        display: flex;
        align-items: center;

        &-columns {
            margin-right: 12px;
        }
    }

    &-pagination {
        margin-top: 20px;
    }

    &-scroll {
        overflow-x: scroll;
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: 11px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid #FFF;
            background-color: rgba(0, 0, 0, .25);
        }
        &::-webkit-scrollbar-thumb:horizontal:hover {
            background-color: rgba(0, 0, 0, .5);
        }
        &::-webkit-scrollbar-thumb:vertical:hover {
            background-color: rgba(0, 0, 0, .5);
        }
    }

    &-content {
        width: 100%;
        border: 0;

        & td, th {
            border-bottom: 1px solid #E9ECEF;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            min-width: 160px;
            text-align: left;
        }

        & th {
            text-transform: uppercase;
            color: #666666;
            padding: 10px 12px;

            &.smallCell {
                padding: 10px 4px;
            }

            &.sortable {
                cursor: pointer;
            }
        }

        & td {
            color: #333333;
            padding: 18px 12px;
            line-height: 20px;
        }
    }

    &-head-row {
        background-color: #F4F7FA;
    }

    &-row {
        background-color: #F4F7FA;

        &-white {
            background-color: #FFF;
        }
    }

    &-cell {
        font-size: 16px;

        &.alignCenter {
            text-align: center;
        }

        &.noPadding {
            padding: 0;
        }

        &.smallFontSize {
            font-size: 12px;
            line-height: 16px;
        }

        &.smallCell {
             min-width: 90px;
         }

        &.largeCell {
            min-width: 200px;
        }

        &.extraLargeCell {
            min-width: 300px;
        }

        &-sort {
            display: inline-block;
            height: 10px;
            width: 10px;
            margin-left: 5px;
        }
    }
}

.ColumnsDropdown {
    position: relative;

    &-button {
        background-color: #F0F3F5;
        border: 1px solid #E9ECEF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        text-align: center;
        color: #333333;
        padding: 8px 16px;
        cursor: pointer;
        display: flex;

        & > svg {
            width: 10px;
            margin-left: 10px;
        }
    }

    &-options {
        position: absolute;
        left: -50px;
        width: calc(100% + 50px);
        top: calc(100% + 8px);
        background: #FFFFFF;
        padding: 8px 0;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.06), 0 2px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        z-index: 10;
    }

    &-option {
        display: flex;
        padding: 10px 20px;
        cursor: pointer;

        &.borderBottom {
            border-bottom: 1px solid #E9ECEF;
        }

        &-icon {
            color: #BDBDBD;
            margin-right: 10px;

            &.active {
                color: #605FF3;
            }
        }
    }
}

.Search {
    display: flex;
    align-items: center;
    background: #F4F7FA;
    border-radius: 6px;
    padding: 14px 12px;

    & > input {
        background: #F4F7FA;
        padding: 0 0 0 20px;
        border: 0;
        outline: 0;
        color: #333333;

        &::placeholder {
            color: #8B949E;
        }
    }
}
