.login-container {
  // background-color: #e9ecef;
  background-color: "white";
  display: flex;
  justify-content: center;
}

.login-card {
  width: 30rem;
  margin-top: -7rem;
  //margin-bottom: auto;
  display: flex;
}

.card {
  margin-bottom: 70%;
  z-index: 100;
  border-radius: 0.5rem;
}

.error {
  color: red;
  font-size: 12.5px;
}
