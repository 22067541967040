.error {
  color: red;
  font-size: 12.5px;
}

button {
  font-size: 0.9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.button-default {
  background: #247ba0;
  color: #fff;
}

.mymodal {
  position: fixed;
  // padding-top: 30%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  // background: green;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.myoverlay {
  position: fixed;
  padding: 20vh 7vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.saveButton {
  background-color: green;
}
.deleteButton {
  background-color: red;
}

// .ReactModal__Overlay {
// 	opacity: 0;
// 	transition: opacity 500ms ease-in-out;
// }

// .ReactModal__Overlay--after-open {
// 	opacity: 1;
// }

// .ReactModal__Overlay--before-close {
// 	opacity: 0;
// }

.whiteModalOverlay {
  position: fixed;
  padding: 15vh 7vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999998;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.delModal {
  position: fixed;
  background: lightgrey;
  width: 80%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // z-index: 99999;
}

.delModalOverlay {
  position: fixed;
  padding: 15vh 7vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999998;
  display: flex;
  flex-direction: column;
}

/**------thermal toggle switch--------**/

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: "Yes";
      text-transform: uppercase;
      padding-left: 10px;
      background-color: #5e72e4; //primary blue
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: "No";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    height: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0px;
    bottom: 6px;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}



.delModalConfirm {
  position: fixed;
  background: lightgrey;
  width: 70%;
  height: 26vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.hiddenButton {
  opacity: 0;
}


.thermoOn {
  color: red;
  font-size: 1.1rem;
}

.thermoOff {
  color: grey;
  font-size: 1.1em;
  opacity: 80%;
}

.searchselect input{
  color: #8898aa;
  background: rgb(244, 247, 250);
  font-size: 0.875rem;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  height: calc(1.5em + 1.25rem + 5px);
  padding: 0.625rem 0.75rem;
}

.searchselect input:focus{
  color: #8898aa;
  background: rgb(244, 247, 250);
  font-size: 0.875rem;
  width: 100%;
  border: 1px solid;
  border-color: #5e72e4;
  border-radius: 0.25rem;
  height: calc(1.5em + 1.25rem + 5px);
  padding: 0.625rem 0.75rem;
}

.searchselect input::placeholder {
  color: #8898aa;
}

.searchselect__select {
  position: relative;
}

.searchselect__select ul{
  position: absolute;
  left: 0; top: 100%;
  width: 100%;
  z-index: 2;
  background: rgb(244, 247, 250);
  border: 1px solid;
  border-color: #5e72e4;
  border-radius: 0.25rem;
  list-style-type: none;
  padding-left: 0rem;
}

.searchselect__select ul li button{
  background: rgb(244, 247, 250);
  color: #8898aa;
}

.searchselect-light input{
  color: #8898aa;
  background: #fff;
  font-size: 0.875rem;
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  height: calc(1.5em + 1.25rem + 5px);
  padding: 0.625rem 0.75rem;
}

.searchselect-light input:focus{
  color: #8898aa;
  background: #fff;
  font-size: 0.875rem;
  width: 100%;
  border: 1px solid;
  border-color: #5e72e4;
  border-radius: 0.25rem;
  height: calc(1.5em + 1.25rem + 5px);
  padding: 0.625rem 0.75rem;
}

.searchselect-light input::placeholder {
  color: #8898aa;
}

.searchselect-light__select {
  position: relative;
}

.searchselect-light__select ul{
  position: absolute;
  left: 0; top: 100%;
  width: 100%;
  z-index: 2;
  background: #fff;
  border: 1px solid;
  border-color: #5e72e4;
  border-radius: 0.25rem;
  list-style-type: none;
  padding-left: 0rem;
}

.searchselect-light__select ul li button{
  background: #fff;
  color: #8898aa;
}

.searchselect-grey input{
  background: #F4F7FA;
  border: 0;
}
