.card {
    margin-bottom: 22px !important;
}

.header {
    background: #4cc3fa;
    background-image: url("../../assets/pattern.png");
    background-size: cover;
    background-repeat: repeat-x;
    
    .h1, h5 {
        color: #666666;
    }
}

.card-stats.card {
    min-height: 6.75rem !important;
}

.icon.icon-shape.text-grey.rounded-circle.shadow {
    background: #F0F3F5;
    border: 1px solid #E9ECEF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
}
